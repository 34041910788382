<template>
  <div class="intro-y box w-full xl:w-1/2 mt-5">
    <div
      class="
        flex flex-col
        sm:flex-row
        items-center
        p-5
        border-b border-gray-200
        dark:border-dark-5
      "
    >
      <h2 class="font-medium text-base mr-auto">{{ $t('views.emission.mint.title') }}</h2>
    </div>
    <div class="p-5 relative">
      <LoadingBlock v-if="processing" />

      <div class="relative">
        <label for="form-input-1" class="form-label uppercase">{{ $t('views.emission.mint.amount') }}</label>
        <input
          id="form-input-1"
          type="text"
          class="form-control form-control-lg text-lg"
          placeholder="0"
          v-model="mint.amount"
          @update:modelValue="calculateFee"
          v-on:keypress="$h.onlyFloat"
          :class="{ 'border-theme-6': validations.visible && validations.amount }"
        />
        <div class="absolute right-5 top-9 text-lg uppercase">
          {{ mint.denom }}
        </div>
        <div class="text-theme-6 mt-2" v-if="validations.visible && validations.amount">{{ validations.amount }}</div>
      </div>
      
      <div class="flex items-center mt-2">
        <div class="mr-1">{{ $t('views.emission.mint.totalReserve') }}:</div>
        <div class="font-semibold uppercase">
          {{ reserve.amount }} {{ reserve.denom }}
        </div>
      </div>

      <div class="flex items-center mt-1">
        <div class="mr-1">{{ $t('views.emission.mint.totalSupply') }}:</div>
        <div class="font-semibold uppercase">
          {{ supply.amount }} {{ supply.denom }}
        </div>
      </div>

      <div class="mt-5 flex items-center">
        <div class="flex">
          <div class="mr-1">{{ $t('common.fee') }}:</div>
          <div class="font-semibold uppercase">
            {{ fee.amount }} {{ fee.denom }}
          </div>
        </div>
        <div class="ml-auto flex">
          <button
            class="btn btn-secondary w-24 mr-1 mb-2"
            v-on:click="resetMint"
          >
            {{ $t('views.emission.mint.reset') }}
          </button>
          <button
            class="btn btn-primary w-24 mr-1 mb-2"
            v-on:click="sendMint"
          >
            {{ $t('views.emission.mint.mint') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import BigNumber from "bignumber.js";
import Constants from "@/utils/constants";
import Coin from "@/utils/coin";
import LoadingBlock from '@/components/common/LoadingBlock.vue'

export default defineComponent({
  name: "Mint",
  components: {
    LoadingBlock
  },
  data: function () {
    return {
      mint: {
        amount: "",
        denom: Constants.DEFAULT_DISPLAY_CURRENCY,
      },
      validations: {
        amount: '',
        visible: false
      },
      address: "",
      processing: false,
      gas: 0,
      fee: { amount: "0", denom: Constants.DEFAULT_DISPLAY_CURRENCY },
    };
  },
  computed: {
    reserve: function () {
      const coin = new Coin(this.$store, Constants.DEFAULT_CURRENCY);
      const supplies = this.$store.getters[
        "cosmos.bank.v1beta1/getTotalSupply"
      ]({ params: {} });
      const supply = supplies.supply.find(
        (s) => s.denom == Constants.DEFAULT_CURRENCY
      );
      const reserves = this.$store.getters[
        "metdeal.cryptounce.depository.v1/getReserves"
      ]({ params: {} });
      const reserve = reserves?.reserves?.find(
        (r) => r.denom == Constants.DEFAULT_CURRENCY
      );
      if (reserve) {
        return coin.toDisplay(
          BigNumber(reserve.value).minus(BigNumber(supply.amount)).toFixed()
        );
      }
      return { amount: "0", denom: Constants.DEFAULT_DISPLAY_CURRENCY };
    },
    supply: function () {
      const coin = new Coin(this.$store, Constants.DEFAULT_CURRENCY);
      const supplies = this.$store.getters[
        "cosmos.bank.v1beta1/getTotalSupply"
      ]({ params: {} });
      const supply = supplies.supply.find(
        (s) => s.denom == Constants.DEFAULT_CURRENCY
      );
      return coin.toDisplay(supply.amount);
    },
  },
  mounted: async function () {
    this.address = this.$store.getters["common/wallet/address"];
    await this.resetMint();
  },
  methods: {
    validate: function(visible = false) {
      this.validations.amount = this.$h.validAmount(this.mint.amount) ? '' : this.$i18n.t('validations.invalidAmount')
      this.validations.visible = visible
      if (!this.validations.amount) {
        const coin = new Coin(this.$store, Constants.DEFAULT_CURRENCY);
        let supply = coin.toBase(this.supply.amount)
        supply = BigNumber(supply.amount)
        if (BigNumber(coin.toBase(this.mint.amount).amount).isGreaterThan(supply)) {
          this.validations.amount = this.$i18n.t('validations.insufficientFunds')
        }
      }
      return !this.validations.amount
    },
    calculateFee: _.debounce(async function ($value) {
      if (this.validate(false)) {
        const coin = new Coin(this.$store, Constants.DEFAULT_CURRENCY);
        const value = {
          minter: this.address,
          amount: coin.toBase(this.mint.amount),
        };
        try {
          const simulateResult = await this.$store.dispatch(
            "metdeal.cryptounce.depository.v1/sendMsgMintSimulate",
            {
              value,
              fee: [{ amount: "0", denom: Constants.DEFAULT_CURRENCY }],
              memo: "",
            }
          );
          this.gas = simulateResult.calculatedGas;

          let baseAmount = BigNumber(this.gas)
            .multipliedBy(BigNumber(Constants.GAS_PRICE))
            .multipliedBy(BigNumber(10).exponentiatedBy(coin.denomExponent()));
          baseAmount = baseAmount.integerValue(BigNumber.ROUND_CEIL);
          this.fee = coin.toDisplay(baseAmount);
        } catch (e) {
          console.error(e);
        }
      }
    }, 500),
    resetMint: async function () {
      await this.$store.dispatch(
        "cosmos.bank.v1beta1/QueryTotalSupply",
        { options: {} },
        {}
      );
      await this.$store.dispatch(
        "metdeal.cryptounce.depository.v1/QueryReserves",
        { options: {} },
        {}
      );
      this.mint.amount = "";
      this.mint.denom = Constants.DEFAULT_DISPLAY_CURRENCY;
      this.gas = 0;
      this.fee = { amount: "0", denom: Constants.DEFAULT_DISPLAY_CURRENCY };
      this.processing = false;
    },
    sendMint: async function () {
      if (this.validate(true) && !this.processing) {
        try {
          const coin = new Coin(this.$store, Constants.DEFAULT_CURRENCY);
          const value = {
            minter: this.address,
            amount: coin.toBase(this.mint.amount),
          };
          this.processing = true;
          const txResult = await this.$store.dispatch(
            "metdeal.cryptounce.depository.v1/sendMsgMint",
            {
              value,
              fee: [coin.toBase(this.fee.amount)],
              memo: "",
              gas: this.gas.toString(),
            }
          );
          if (txResult && !txResult.code) {
            this.$toast.success(this.$i18n.t('views.emission.notificationSuccessMinted'))
            this.resetMint();
          }
        } catch (e) {
          console.error(e);
        } finally {
          this.processing = false;
        }
      }
    },
  },
});
</script>
