<template>
  <Mint />
  <Burn />
</template>

<script>
import { defineComponent } from "vue";
import Mint from '@/components/emission/Mint.vue'
import Burn from '@/components/emission/Burn.vue'


export default defineComponent({
  components: {
    Mint,
    Burn
  },
  mounted: function () {
    this.$store.commit("updateTopBarTitle", this.$i18n.t("menu.emission"));
  },
});
</script>
